.login-page{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.form{
  padding: 40px;
  border: 1px solid #9c9c9c;
  border-radius: 10px;
}
.red{
  color: red;
}