.about-header{
padding: 2rem 0 0 0;
background-color: #F7FAFB;
}

.about-header__heading{
    color: #333333;
    font-family: "Poppins",sans-serif;
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 4.5rem;
    text-align: center;
}

.about-content{
    padding: 6.875rem 0px;
}

.about-content__col-text{
    color: #333333;
  font-family: "Poppins",sans-serif;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.813rem;
  text-align: justify;
}

.primary-color{
    color: #27BBAD;
}
.aboutImg{
    width: 500px;
    height: 600px;
}