.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 0.625rem 1.875rem;
  position: relative;
}

#main-layout {
  background-color: #ffffff !important;
}

body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #f7fafb;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #27bbad;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #f7fafb;  /* creates padding around scroll thumb */
}

html{
  font-size: 100%;
}

@media screen and (max-width : 1200px){
  html{
    font-size: 90%;
  }
  }

@media screen and (max-width : 992px){
  html{
    font-size: 80%;
  }
  }

@media screen and (max-width : 768px){
  html{
    font-size: 70%;
  }
  }

@media screen and (max-width : 576px){
html{
  font-size: 60%;
}
}

@media screen and (max-width : 400px){
  html{
    font-size: 50%;
  }
  }