.primary-card {
  background-color: #27bbad;
  padding: 1.438rem 1.875rem;
  border-radius: 10px;
}

.primary-card .ant-card-cover {
  display: inline-block;
  padding: 1.563rem;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
}

.primary-card .ant-card-cover img {
  width: inherit;
}

.primary-card .ant-card-meta-title {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  opacity: 0.9;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.875rem;
}

.primary-card .ant-card-body {
  padding: 1.5rem 0px;
}

.primary-card .ant-card-actions {
  background-color: #27bbad;
  border-top: 0px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.563rem;
  opacity: 0.9;
}

.primary-card .ant-card-actions .ant-space-item {
  opacity: 0.9;
  color: #ffffff;
}

.primary-card .ant-card-actions > li {
  text-align: left;
}
