.ant-layout-header {
  background: transparent !important;
  height: 91px !important;
  padding: 0 30px !important;
  position: relative;
  z-index: 1000;
}

.ant-affix .ant-layout-header {
  opacity: 0.92;
}

.hamburger-menu .hamburger-menu-div {
  position: absolute !important;
  right: 0;
  top: 35px;
  width: 100%;
}

.hamburger-menu-button {
  position: absolute !important;
  right: 0;
  top: 50%;
  margin-top: -13px;
  background: #eeee !important;
  border-color: #27bbad !important;
  z-index: 100;
}

.hamburger-menu-button span {
  color: #27bbad !important;
}

.hamburger-menu .ant-menu-vertical {
  width: 100% !important;
  opacity: 0.92;
  z-index: 1;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #fefefe !important;
  display: block !important;
  visibility: hidden;
  opacity: 0;
  margin-top: 50px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.black-navbar .ant-menu-vertical {
  border: 1px solid #27bbad !important;
}
.hamburger-menu .showmenu {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

.white-navbar .ant-menu-vertical {
  background-color: #eee !important;
  z-index: 100;
  border: 1px solid #174cb8 !important;
}

.black-navbar .ant-menu-vertical {
  background-color: #eee !important;
  z-index: 100;
}

.hamburger-menu .ant-menu-vertical li,
.hamburger-menu .ant-menu-vertical li a {
  padding-left: 0px;
}

.hamburger-menu .ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  top: 52px !important;
}

.header .ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  position: absolute;
  top: 13px;
  right: 0;
  width: 98%;
  justify-content: flex-end;
  font-family: "Titillium Web", sans-serif;
}

.header .navbar-logo-img {
  /* position: absolute;
  top: 6px;
  left: 25px;
  width: 80px; */
}

.header .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  padding: 0 25px;
}

.white-navbar .ant-menu-dark .ant-menu-item,
.white-navbar .ant-menu-dark .ant-menu-item-group-title,
.white-navbar .ant-menu-dark .ant-menu-item > a,
.white-navbar .ant-menu-dark .ant-menu-item > span > a,
.white-navbar .anticon {
  color: #ffffff;
}

.white-navbar {
  background: #ffffff !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.11) !important;
}

.black-navbar .ant-menu-item-selected a {
  color: #174cb8 !important;
}

.black-navbar {
  background: #f7fafb !important;
}

.black-navbar .ant-menu-dark .ant-menu-item,
.black-navbar .ant-menu-dark .ant-menu-item-group-title,
.black-navbar .ant-menu-dark .ant-menu-item > a,
.black-navbar .ant-menu-dark .ant-menu-item > span > a,
.black-navbar .anticon {
  color: #222222;
}

.black-navbar .ant-menu-horizontal > .ant-menu-item span:hover{
  color: #27bbad !important;
}

.black-navbar .ant-menu-horizontal > .ant-menu-item span:hover .anticon-down svg{
  fill: #27bbad !important; 
}

.overlap-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

/* .black-navbar li:hover a{
  color :#174cb8 !important
} */

.header .ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: transparent;
}

.header .ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent !important;
}
/* 
.white-header .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: transparent;
} */

.header .navbar-item span {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #999999 !important;
}

.header .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: transparent !important;
}

.header .ant-menu-item:hover a {
  color: #000 !important;
}

.header .ant-menu-item-selected span,
.header .ant-menu-item-selected:hover span {
  color: #27bbad !important;
  cursor: auto;
}

.header .anticon-down svg{
  fill: #999999;
}

.header .navbar-item span:hover .anticon-down svg, .header .ant-menu-item-selected .anticon-down svg{
  fill : #27bbad !important; 
}


/* ------drawer--------- */

.ant-drawer-open{
  height : 92% !important;
  top : 90px !important;
  /* left: -27px !important; */
}

.navigation-drawer{
  max-width: 1440px;
  margin: 0 auto;
  /* left: -18px; */
  right:  0;
  z-index: 900;
}

.navigation-drawer .ant-drawer-content-wrapper{
  height: 26.688rem !important;
  box-shadow: none !important;
}

.navigation-drawer .ant-drawer-wrapper-body{
  max-width: 1200px;
margin: 0 auto;
}

.navigation-drawer .ant-drawer-content{
  background-color: #f7fafb ;
}

.navigation-drawer-heading{
  opacity: 0.5;
  color: #999999;
  font-family: "Poppins",sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1.563rem;
}

.navigation-drawer-list{
  list-style: none;
    padding-left: 0px;
}

.navigation-drawer-item{
  color: #999999;
  font-family: "Poppins",sans-serif;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.563rem;
  padding: 5px 0;
  background-color: #f7fafb;
}

.navigation-drawer .navigation-drawer-list .navigation-drawer-item{

  margin: 0px;
  margin-bottom: 0px;
  padding: 0px ;
  padding-left: 0px !important;
  height: 60px;
}

.navigation-drawer-list .navigation-drawer-item.ant-menu-item-active{
  color : #27bbad;
}

.categories-drawer__image{
width :36px;
margin-right :15px;
}

.categories-drawer__text{
  color: #323232;
  font-family: "Poppins",sans-serif;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.563rem;
  margin-bottom: 0px;
}

.categories-drawer__col{
  display: flex;
  align-items: center;
  padding: 36px 0;
}

#main-layout {
  background-color: #ffffff !important;
}

.navigation-drawer .ant-drawer-body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

.navigation-drawer .ant-drawer-body::-webkit-scrollbar-track {
  background: #f7fafb;        /* color of the tracking area */
}

.navigation-drawer .ant-drawer-body::-webkit-scrollbar-thumb {
  background-color: #27bbad;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #f7fafb;  /* creates padding around scroll thumb */
}

.navbar-item span{
  margin-left: 5px;
}

.show-more-button{
  color : #27bbad;
  cursor: pointer;
}

/* 
.papers-drawer-body .ant-col,.papers-drawer-body .ant-menu{
  text-align: center;
} */

@media only screen and (max-width: 1600px) {
  .header .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    padding: 0 13px;
  }

  .ant-layout-header {
    padding: 0 25px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .header .ant-menu-title-content a {
    font-size: 15px;
  }

  .header .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    padding: 0 19px;
  }

  .ant-layout-header {
    padding: 0 20px !important;
  }
}

@media only screen and (max-width: 991px) {
  .header .ant-menu-title-content a {
    font-size: 14px;
  }

  .header .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    padding: 0 16px;
  }

  .ant-layout-header {
    padding: 0 15px !important;
  }
}

@media only screen and (max-width: 870px) {
  .header .ant-menu-title-content a {
    font-size: 14px;
  }

  .header .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    padding: 0 12px;
  }

  .ant-layout-header {
    padding: 0 12px !important;
  }
}

@media only screen and (max-width: 768px) {
  .header .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    width: 62%;
  }

  .header .ant-menu-title-content a {
    font-size: 15px;
  }
}

@media only screen and (max-width: 580px) {
  .header .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    width: 50%;
  }

  .ant-layout-header {
    padding: 0 25px !important;
    height: 90px !important;
  }
}

@media only screen and (max-width: 400px) {
  .header .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    width: 33%;
  }
}
