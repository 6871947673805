.most-repeated-content{
    padding: 4.375rem;
}

.most-repeated-list-item{
    background-color: #FFFFFF;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 5%);
    border-bottom: 0px !important;
}

.list-item-link-button{
cursor: pointer;
padding :5px 0;
display: flex;
align-items: center;
}

.list-item-link-button .anticon{
    font-size: 1rem;
    margin-right: 3px;
}

.align-right{
    text-align: right;
}