.d-Title{
    text-align: center;
}
.delete-submit{
    display: block;
    margin-top: 10px;
    margin-left: 212px;

}
.radio-left{
    margin-left:184px;;
 
}
.ant-table{
    overflow: auto;
    height: 53vh;
}
