.question{
    height: 70%;
    display: flex;
    flex-direction: column;
}

.number{
    margin: 0;
    padding: 0;
    font-size: 4rem;
    color: #a4a4bc;
}

.statement{
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 2rem;
    font-weight: lighter;
}

.answer{
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 1rem;
    font-weight: lighter;
}

.display{
    width: 50%;
    margin-left: 25%; 
    background: #0a7500;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 10px;
    height: 50px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    box-shadow: 2px 2px 5px #babecc, -5px -5px 10px #ffffff73;

}
.scroll{
    overflow: auto;
    height: 70vh;
}
/* .spin-set > .ant-spin-spinning{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
} */
@media screen and (min-width: 600px) {
    .number{
        font-size: 6rem;;
    }
}

@media screen and (min-width: 1024px) {
    .number{
        font-size: 8rem;;
    }
}